var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "CALENDAR_EXCEPTION_" + _vm.editEvent.uuId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.editEvent.uuId
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "danger" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.close")))]
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pr-md-2", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("calendar.field.name"),
                        "label-for": "name",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "name",
                              type: "text",
                              "data-vv-as": _vm.$t("calendar.field.name"),
                              "data-vv-name": "editEvent.name",
                              "data-vv-delay": "500",
                              readonly: _vm.readOnly,
                            },
                            model: {
                              value: _vm.editEvent.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.editEvent, "name", $$v)
                              },
                              expression: "editEvent.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showNameError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("editEvent.name")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-md-2", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("field.identifier"),
                        "label-for": "identifier",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "identifier",
                              type: "text",
                              "data-vv-as": _vm.$t("field.identifier"),
                              "data-vv-name": "calendar.field.identifier",
                              maxlength: _vm.maxIdentifierLength,
                              disabled: _vm.readOnly,
                              trim: "",
                            },
                            model: {
                              value: _vm.editEvent.identifier,
                              callback: function ($$v) {
                                _vm.$set(_vm.editEvent, "identifier", $$v)
                              },
                              expression: "editEvent.identifier",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pr-md-2", attrs: { cols: "12", md: "6" } },
                [
                  _c("label", { attrs: { for: "exception-startDate" } }, [
                    _vm._v(_vm._s(_vm.$t("calendar.field.startDate"))),
                  ]),
                  _c("b-form-datepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }",
                      },
                    ],
                    class: { "mb-3": !_vm.showStartDateError },
                    attrs: {
                      id: "exception-startDate",
                      max: _vm.editEvent.endDate,
                      "today-button": "",
                      "reset-button": "",
                      "close-button": "",
                      "hide-header": "",
                      readonly: _vm.readOnly,
                      "label-today-button": _vm.$t("date.today"),
                      "label-reset-button": _vm.$t("date.reset"),
                      "label-close-button": _vm.$t("date.close"),
                      "today-button-variant": "primary",
                      "reset-button-variant": "danger",
                      "close-button-variant": "secondary",
                      state: _vm.fieldValidateUtil.stateValidate(
                        _vm.readOnly,
                        _vm.veeFields,
                        _vm.errors,
                        "editEvent.startDate"
                      ),
                      "data-vv-as": _vm.$t("calendar.field.startDate"),
                      "data-vv-name": "editEvent.startDate",
                      "data-vv-delay": "500",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function (ref) {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "calendar-days"] },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.editEvent.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editEvent, "startDate", $$v)
                      },
                      expression: "editEvent.startDate",
                    },
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert mb-3",
                      class: { "d-block": _vm.showStartDateError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " +
                          _vm._s(_vm.errors.first("editEvent.startDate")) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-md-2", attrs: { cols: "12", md: "6" } },
                [
                  _c("label", { attrs: { for: "exception-endDate" } }, [
                    _vm._v(_vm._s(_vm.$t("calendar.field.endDate"))),
                  ]),
                  _c("b-form-datepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }",
                      },
                    ],
                    class: { "mb-3": !_vm.showEndDateError },
                    attrs: {
                      id: "exception-endDate",
                      min: _vm.editEvent.startDate,
                      "today-button": "",
                      "reset-button": "",
                      "close-button": "",
                      "hide-header": "",
                      readonly: _vm.readOnly,
                      "label-today-button": _vm.$t("date.today"),
                      "label-reset-button": _vm.$t("date.reset"),
                      "label-close-button": _vm.$t("date.close"),
                      "today-button-variant": "primary",
                      "reset-button-variant": "danger",
                      "close-button-variant": "secondary",
                      state: _vm.fieldValidateUtil.stateValidate(
                        _vm.readOnly,
                        _vm.veeFields,
                        _vm.errors,
                        "editEvent.endDate"
                      ),
                      "data-vv-as": _vm.$t("calendar.field.endDate"),
                      "data-vv-name": "editEvent.endDate",
                      "data-vv-delay": "500",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function (ref) {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "calendar-days"] },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.editEvent.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editEvent, "endDate", $$v)
                      },
                      expression: "editEvent.endDate",
                    },
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert mb-3",
                      class: { "d-block": _vm.showEndDateError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " +
                          _vm._s(_vm.errors.first("editEvent.endDate")) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "" } },
            [
              _c(
                "b-form-radio-group",
                {
                  attrs: { id: "working", name: "isWorking" },
                  on: { change: _vm.workingChange },
                  model: {
                    value: _vm.editEvent.isWorking,
                    callback: function ($$v) {
                      _vm.$set(_vm.editEvent, "isWorking", $$v)
                    },
                    expression: "editEvent.isWorking",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    { attrs: { disabled: _vm.readOnly, value: false } },
                    [_vm._v(_vm._s(_vm.$t("calendar.non_working")))]
                  ),
                  _c(
                    "b-form-radio",
                    { attrs: { disabled: _vm.readOnly, value: true } },
                    [_vm._v(_vm._s(_vm.$t("calendar.working")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isWorking
            ? _c(
                "b-row",
                { staticClass: "calendar-exception-time" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-md-2", attrs: { cols: "12", md: "6" } },
                    [
                      _c("label", { attrs: { for: "startHour" } }, [
                        _vm._v(_vm._s(_vm.$t("calendar.field.from"))),
                      ]),
                      _c("Timepicker", {
                        attrs: { readonly: _vm.readOnly },
                        on: {
                          context: function ($event) {
                            return _vm.onContext($event, "startHour")
                          },
                        },
                        model: {
                          value: _vm.editEvent.startHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.editEvent, "startHour", $$v)
                          },
                          expression: "editEvent.startHour",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert mb-3",
                          class: { "d-block": _vm.showStartHourError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("editEvent.startHour")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pl-md-2", attrs: { cols: "12", md: "6" } },
                    [
                      _c("label", { attrs: { for: "endHour" } }, [
                        _vm._v(_vm._s(_vm.$t("calendar.field.to"))),
                      ]),
                      _c("Timepicker", {
                        attrs: { readonly: _vm.readOnly },
                        on: {
                          context: function ($event) {
                            return _vm.onContext($event, "startHour")
                          },
                        },
                        model: {
                          value: _vm.editEvent.endHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.editEvent, "endHour", $$v)
                          },
                          expression: "editEvent.endHour",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert mb-3",
                          class: { "d-block": _vm.showEndHourError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("editEvent.endHour")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.state.historyShow
        ? _c("GenericHistoryModal", {
            attrs: {
              show: _vm.state.historyShow,
              id: _vm.editEvent.uuId,
              entityType: "CALENDAR",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "historyShow", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }